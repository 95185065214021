import classNames from 'classnames';
import { DateTime } from 'luxon';
import { $$, $e, registerStartup } from 'lib/utils';

type Holidays = { readonly [date in string]?: string };

const weekdayNames = ['日', '月', '火', '水', '木', '金', '土'] as const;
const weekdayAbbrs = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'] as const;

registerStartup(async () => {
  const inputs = $$<HTMLInputElement>('.calendar-select');
  if (!inputs.length) return;

  const res = await fetch('https://cdn.aisrvs.net/jp/holidays.json');
  const holidays: Holidays = await res.json();
  inputs.forEach((input) => new Calendar(input, holidays));
});

class Calendar {
  readonly min: DateTime;
  readonly max: DateTime;
  readonly unselectables: Set<string>;
  readonly cntr: HTMLDivElement;

  constructor(readonly input: HTMLInputElement, readonly holidays: Holidays) {
    this.min = DateTime.fromISO(input.min);
    this.max = DateTime.fromISO(input.max);
    this.unselectables = new Set<string>(JSON.parse(input.dataset.unselectables!));
    this.cntr = $e('div', { className: 'calendar-container' });
    this.build((this.selected() || this.min).startOf('month'));
    input.classList.add('sr-only');
    input.after(this.cntr);
  }

  selected() {
    const value = this.input.value;
    if (!value) return;

    const date = DateTime.fromISO(value);
    if (!date.isValid || this.unselectables.has(date.toISODate()) || date < this.min || date > this.max) {
      this.input.value = '';
      return;
    }

    return date;
  }

  build(month: DateTime) {
    const selected = this.selected();
    const disabled = this.input.disabled;
    const today = DateTime.now().startOf('day');
    const tbody = $e('tbody');
    const table = $e('table', { className: 'calendar-table' }, this.caption(month), calendarHeader(), tbody);
    let start = month.minus({ days: month.weekday % 7 });
    do {
      const week = Array.from({ length: 7 }, (_, i) => {
        const date = start.plus({ days: i });
        const isOutOfMonth = !date.hasSame(month, 'month');
        const isToday = !isOutOfMonth && date.hasSame(today, 'day');
        const isHoliday = this.holidays[date.toFormat('yyyy/MM/dd')];
        const isSelectable = this.min <= date && this.max >= date && !this.unselectables.has(date.toISODate()!);
        const className = classNames('calendar-day', `calendar-${weekdayAbbrs[date.weekday % 7]}`, {
          'calendar-today': isToday,
          'calendar-holiday': isHoliday,
          'calendar-selected': selected && date.hasSame(selected, 'day'),
          'calendar-selectable': isSelectable,
          'calendar-unselectable': !isSelectable,
          'calendar-disabled': disabled,
        });
        const texts = $e(
          'span',
          { className: 'calendar-day-texts' },
          $e('span', { className: 'calendar-day-text' }, isOutOfMonth ? date.toFormat('M/d') : `${date.day}`),
        );
        const td = $e('td', { className }, texts);
        if (isHoliday) texts.append($e('span', { className: 'calendar-holiday-name' }, isHoliday));
        if (isSelectable && !disabled) td.addEventListener('click', () => this.onSelect(date, td));
        return td;
      });
      tbody.append($e('tr', {}, ...week));
      start = start.plus({ week: 1 });
    } while (start.hasSame(month, 'month'));
    this.cntr.replaceChildren(table);
  }

  caption(month: DateTime) {
    const prev = $e(
      'button',
      { className: 'btn btn-sm', disabled: month.hasSame(this.min, 'month') },
      $e('i', { className: 'fa-solid fa-angles-left mr-1' }),
      '前の月',
    );
    const next = $e(
      'button',
      { className: 'btn btn-sm', disabled: month.hasSame(this.max, 'month') },
      '次の月',
      $e('i', { className: 'fa-solid fa-angles-right ml-1' }),
    );
    prev.addEventListener('click', () => this.build(month.minus({ month: 1 })));
    next.addEventListener('click', () => this.build(month.plus({ month: 1 })));
    return $e(
      'caption',
      { className: 'calendar-caption' },
      $e(
        'div',
        { className: 'calendar-caption-wrap' },
        prev,
        $e('span', { className: 'calendar-month' }, `${month.year}年${month.month}月`),
        next,
      ),
    );
  }

  onSelect(date: DateTime, td: HTMLTableCellElement) {
    this.input.value = date.toISODate()!;
    this.cntr.querySelectorAll('.calendar-selected').forEach((elem) => elem.classList.remove('calendar-selected'));
    td.classList.add('calendar-selected');
  }
}

function calendarHeader() {
  const weekdays = Array.from({ length: 7 }, (_, i) =>
    $e('th', { className: `calendar-${weekdayAbbrs[i]}`, scope: 'column' }, weekdayNames[i]),
  );
  return $e('thead', undefined, $e('tr', undefined, ...weekdays));
}
