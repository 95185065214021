import { $, EventOf, on, registerStartup } from 'lib/utils';

registerStartup(() => {
  on('click', 'a[href*="#"]', (ev: EventOf<HTMLAnchorElement>) => {
    const href = new URL(ev.currentTarget.href, location.href);
    if (href.origin !== location.origin || href.pathname !== location.pathname || !href.hash) return;

    let top = 0;
    ev.preventDefault();
    if (href.hash !== '#top') {
      const targetTop = ($(href.hash.slice(1))?.getBoundingClientRect().top ?? 0) + scrollY;
      const headerHeight = $('page-header')?.getBoundingClientRect().height ?? 0;
      top = targetTop - headerHeight;
    }
    history.pushState(null, '', href.hash);
    scrollTo({ top, behavior: 'smooth' });
  });
});
