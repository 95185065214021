import 'lib/rails';
import 'lib/luxon-setup';

import { runStartups } from 'lib/utils';
import 'app/validations';
import 'app/scroll';
import 'app/photoswipe';
import 'app/enableon';
import 'app/onetime';
import 'app/cart';
import 'app/favorite';
import 'app/calendar';
import 'app/credit';
import 'app/welcome';

runStartups();
