import Swiper from 'swiper';
import { Autoplay, Pagination, EffectCoverflow } from 'swiper/modules';
import { prefectures } from './validations';
import { showModal } from 'lib/modal';
import { $, $$, csrfToken, EventOf, on, registerStartup } from 'lib/utils';

type Address = [number, string, string] | [number, string, string, string, string];

registerStartup(() => {
  new Swiper('.swiper', {
    modules: [Autoplay, Pagination, EffectCoverflow],
    speed: 500,
    autoplay: { delay: 5000 },
    slidesPerView: 1,
    loop: true,
    effect: 'coverflow',
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
    },
    spaceBetween: 16,
    breakpoints: {
      768: {
        slidesPerView: 3,
        effect: 'slide',
      },
    },
  });

  $('postal-query')?.addEventListener('submit', async (ev) => {
    ev.preventDefault();
    const form = ev.target as HTMLFormElement;
    const postalCode = (form.elements.namedItem('postal-code') as HTMLInputElement)?.value;
    if (!/^\d{3}-?\d{4}$/.test(postalCode)) {
      alert('郵便番号を入力してください。');
      return;
    }

    try {
      const { address, areas } = await checkDeliverable(postalCode.replace(/-/g, ''));

      if (!areas?.length) {
        showAddressModal('destination-ng', postalCode, address);
        return;
      }

      const modal = showAddressModal('destination-ok', postalCode, address);
      modal?.querySelector('.delivery-select')!.addEventListener('click', async () => {
        await setDestination(postalCode, address);
        location.href = '/cart/delivery_select';
      });
    } catch (err) {
      alert(err);
    }
  });

  const fromExtern = getReferrerOrigin() !== location.origin;
  const navType = (performance.getEntriesByType('navigation')[0] as PerformanceNavigationTiming | undefined)?.type;
  console.log(navType);
  $$('.wpop').forEach((pop) => {
    if (
      (fromExtern && (navType !== 'back_forward' || sessionStorage.getItem(`wpop.${pop.id}`) === 'closed')) ||
      navType === 'reload'
    ) {
      sessionStorage.removeItem(`wpop.${pop.id}`);
      pop.hidden = false;
    }
  });
  on('click', '.wpop-close', (ev: EventOf<HTMLButtonElement>) => {
    const pop = ev.currentTarget.closest('.wpop') as HTMLElement;
    sessionStorage.setItem(`wpop.${pop.id}`, 'closed');
    pop.addEventListener('transitionend', () => (pop.hidden = true));
    pop.style.opacity = '0';
  });
});

function showAddressModal(id: string, postalCode: string, address: Address) {
  const modal = $(id);
  if (!modal) return;
  modal.querySelector('.postal_code')!.textContent = postalCode.replace(/^(\d{3})-?/, '$1-');
  modal.querySelector('.address')!.textContent = `${prefectures[address[0] - 1]}${address[1]}${address[2]}`;
  return showModal(modal);
}

function getReferrerOrigin() {
  try {
    return new URL(document.referrer).origin;
  } catch (_err) {
    // ignore
  }
}

async function checkDeliverable(postalCode: string) {
  const address = await getPostalCode(postalCode);
  if (!address) throw new Error(`郵便番号 ${postalCode} が見つかりませんでした。`);
  const res = await fetch(`/ajax/deliverable?${new URLSearchParams({ pref: `${address[0]}`, city: address[1] })}`);
  if (!res.ok) throw new Error(`HTTP ${res.status}`);
  const { areas } = await res.json();
  return { address, areas };
}

async function getPostalCode(postalCode: string) {
  const res = await fetch(`https://cdn.aisrvs.net/jp/postal/${postalCode.slice(0, 3)}.json`);
  if (res.status === 404) return;
  if (!res.ok) throw new Error(`HTTP ${res.status}`);
  const json = await res.json();
  return json[postalCode] as Address;
}

async function setDestination(postalCode: string, address: Address) {
  const formdata = new FormData();
  formdata.set('destination[zip_code]', postalCode);
  formdata.set('destination[prefecture]', prefectures[address[0] - 1]);
  formdata.set('destination[city]', `${address[1]}${address[2]}`);
  formdata.set('destination[street]', address[3] || '');
  const res = await fetch(`/cart/set_destination`, {
    method: 'post',
    body: formdata,
    headers: { 'X-CSRF-Token': csrfToken() },
  });
  if (!res.ok) throw new Error(`HTTP ${res.status}`);
  return res;
}
