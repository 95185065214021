import { normalize } from 'lib/normlize';
import { on, EventOf, registerStartup } from 'lib/utils';

type InputElement = HTMLInputElement & { readonly tagName: 'INPUT' };
type TextAreaElement = HTMLTextAreaElement & { readonly tagName: 'TEXTAREA'; readonly type: 'textarea' };
type SelectElement = HTMLSelectElement & {
  readonly tagName: 'SELECT';
  readonly type: 'select-one' | 'select-multiple';
};
type FormElement = InputElement | TextAreaElement | SelectElement;
type PhoneJson = { readonly regexs: string[]; readonly pattern: string; readonly patternHyphen: string };
type PostalJson = {
  readonly [postalCode in string]?: [number, string, string] | [number, string, string, string, string];
};
type PostalCacheEntry = { readonly code: string; readonly promise: Promise<PostalJson | false> };

// prettier-ignore
export const prefectures = [
  '北海道', '青森県', '岩手県', '宮城県', '秋田県', '山形県', '福島県', '茨城県', '栃木県',
  '群馬県', '埼玉県', '千葉県', '東京都', '神奈川県', '新潟県', '富山県', '石川県', '福井県',
  '山梨県', '長野県', '岐阜県', '静岡県', '愛知県', '三重県', '滋賀県', '京都府', '大阪府',
  '兵庫県', '奈良県', '和歌山県', '鳥取県', '島根県', '岡山県', '広島県', '山口県', '徳島県',
  '香川県', '愛媛県', '高知県', '福岡県', '佐賀県', '長崎県', '熊本県', '大分県', '宮崎県',
  '鹿児島県', '沖縄県',
] as const;

let phone: RegExp[] | undefined = undefined;
const compositionRunning = new WeakSet<object>();
const modifiedElements = new WeakSet<HTMLElement>();
const postalCodeCache = new WeakMap<HTMLElement, PostalCacheEntry>();

registerStartup(() => {
  if (document.querySelector('input[type=tel]')) {
    fetch('https://cdn.aisrvs.net/jp/phone.json')
      .then((res) => res.json() as Promise<PhoneJson>)
      .then(({ regexs }) => (phone = regexs.map((re) => new RegExp(`^(?:${re})$`))))
      .catch(console.error);
  }

  document.body.addEventListener('compositionstart', (ev) => compositionRunning.add(ev.target!));
  document.body.addEventListener('compositionend', (ev) => compositionRunning.delete(ev.target!));

  on(
    ['input', 'compositionend'],
    'input:not([type=radio],[type=checkbox],[type=file]),textarea',
    (ev: EventOf<FormElement>) => {
      if (!compositionRunning.has(ev.target!)) checkElem(ev.currentTarget, false);
    },
  );
  on('focusout', 'input,textarea', (ev: EventOf<FormElement>) => {
    const elem = ev.currentTarget;
    if (modifiedElements.has(elem)) setModified(elem);
  });
  on('change', 'input[type=radio],input[type=checkbox],select', (ev: EventOf<FormElement>) => {
    checkElem(ev.currentTarget, true);
  });
  on('click', '[type=submit]', (ev: EventOf<HTMLButtonElement>) => {
    Array.from(ev.currentTarget.form?.elements || []).forEach((elem) => {
      const input = elem as HTMLInputElement;
      if (input.type !== 'hidden' && !input.matches(':disabled,:read-only')) setModified(elem);
    });
  });
});

export function setValid(elem: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement, modified = false) {
  elem.classList.remove('is-invalid');
  elem.setCustomValidity('');
  if (modified) setModified(elem);
}

export function setInvalid(elem: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement, mesg?: string) {
  elem.classList.add('is-invalid');
  if (mesg != null) elem.setCustomValidity(mesg);
}

export function setModified(elem: Element) {
  if (!elem.closest('.no-modified')) {
    elem.classList.add('is-modified');
  }
}

function checkElem(elem: FormElement, updateModified: boolean) {
  if (elem.value !== '') {
    const normalized = normalize(elem.value, elem);
    if (elem.value !== normalized) elem.value = normalized;
    if (elem.type === 'tel') {
      checkTel(elem);
    } else if (elem.getAttribute('autocomplete')?.includes('postal-code')) {
      checkPostalCode(elem);
    } else {
      checkText(elem);
    }
  } else {
    setValid(elem);
  }
  modifiedElements.add(elem);
  if (updateModified) setModified(elem);
  if (elem.type === 'checkbox' || elem.type === 'radio') {
    const elems = elem.form!.elements.namedItem(elem.name)!;
    ((elems instanceof Element ? [elems] : elems) as Element[]).forEach(setModified);
  }
}

function checkTel(elem: InputElement) {
  if (phone) {
    const value = elem.value.replace(/[- ]/g, '').replace(/^\+?81/, '0');
    for (const re of phone) {
      const match = re.exec(value);
      if (match) {
        const newValue = match.slice(1).join('-');
        if (value !== newValue) elem.value = newValue;
        setValid(elem, true);
        return;
      }
    }
    setInvalid(elem, '正しい電話番号を入力してください。');
  }
}

function checkPostalCode(elem: FormElement) {
  const value = elem.value.replace(/[- ]/g, '');
  const code = value.slice(0, 3);
  let cache: PostalCacheEntry | undefined;
  if (value.length >= 3) {
    cache = postalCodeCache.get(elem);
    if (cache?.code !== code) {
      const promise = fetch(`https://cdn.aisrvs.net/jp/postal/${code}.json`)
        .then((res) => res.ok && res.json())
        .catch(console.error);
      cache = { code, promise };
      postalCodeCache.set(elem, cache);
    }
  }
  if (value.length === 7) {
    cache!.promise.then((postals) => {
      if (!postals) return;
      const postal = postals[value];
      if (postal) {
        const form = elem.closest('form')!;
        elem.value = `${code}-${value.slice(3)}`;
        const fills = {
          level1: prefectures[postal[0] - 1],
          level2: postal[1] + postal[2],
          level3: postal[3],
          line1: postal[4],
          line2: null, // sentinel
        };
        const elems: Record<string, HTMLInputElement | null> = {};
        for (const key of Object.keys(fills)) {
          const input = form.querySelector<InputElement>(`[autocomplete~="address-${key}"]`);
          elems[key] = input;
          if (input?.value) {
            input.value = '';
            setModified(input);
          }
        }
        let focused = false;
        for (const [key, value] of Object.entries(fills)) {
          const elem = elems[key];
          if (elem && value) {
            elem.value += normalize(value);
            modifiedElements.add(elem);
            setModified(elem);
          } else if (!focused) {
            elem?.focus();
            focused = true;
          }
        }
      }
    });
  }
}

function validateConfirmation(elem: FormElement) {
  if (elem.id.endsWith('_confirmation')) {
    const elem2 = document.getElementById(elem.id.replace(/_confirmation$/, '')) as HTMLInputElement;
    if (elem.value !== elem2.value) {
      console.log(`validateConfirmation(${elem.id}): "${elem.value}" != "${elem2.value}"`);
      return '確認の入力が一致しません。';
    }
  }
}

function validatePattern(elem: FormElement) {
  const pattern = elem.dataset.pattern || ('pattern' in elem && elem.pattern);
  if (pattern && !new RegExp(`^(?:${pattern})$`, 'u').test(elem.value)) {
    console.log(`validatePattern(${elem.id}): "${elem.value}" !~ /${pattern}/`);
    return '正しい形式で入力してください。';
  }
}

function validateMin(elem: FormElement) {
  const min = elem.dataset.min || ('min' in elem && elem.min);
  if (min) {
    const value = parseFloat(elem.value);
    if (Number.isNaN(value) || value < parseFloat(min)) {
      console.log(`validateMin(${elem.id}): "${elem.value}" < ${min}`);
      return `${min}以上の値で入力してください。`;
    }
  }
}

function validateMax(elem: FormElement) {
  const max = elem.dataset.max || ('max' in elem && elem.max);
  if (max) {
    const value = parseFloat(elem.value);
    if (Number.isNaN(value) || value > parseFloat(max)) {
      console.log(`validateMax(${elem.id}): "${elem.value}" > ${max}`);
      return `${max}以下の値で入力してください。`;
    }
  }
}

function checkText(elem: FormElement) {
  const mesg = validateConfirmation(elem) || validatePattern(elem) || validateMin(elem) || validateMax(elem);
  if (mesg) {
    setInvalid(elem, elem.dataset.validationMessage || mesg);
  } else {
    setValid(elem);
  }
}
