import PhotoSwipe from 'photoswipe';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import { $$, registerStartup } from 'lib/utils';

registerStartup(() => {
  $$('.gallery').forEach((gallery) => {
    const lightbox = new PhotoSwipeLightbox({ gallery, children: 'a', pswpModule: PhotoSwipe });
    lightbox.init();
  });
});
