import { $e } from 'lib/utils';

export const showModal = <E extends HTMLElement>(el: E) => {
  const content = el.cloneNode(true) as E;
  const overlay = $e('div', { className: 'modal-overlay' }, $e('div', { className: 'modal-content' }, content));
  const remove = () => {
    overlay.remove();
    window.removeEventListener('beforeunload', remove);
    document.body.style.overflow = '';
  };
  content.style.display = 'block';
  document.body.append(overlay);
  overlay.addEventListener('click', (ev) => {
    const target = ev.target as HTMLElement;
    const close = target.closest('.close') || !target.closest('.modal');
    if (close) {
      overlay.addEventListener('transitionend', () => remove());
      overlay.classList.remove('show');
      document.body.style.overflow = '';
    }
  });
  setTimeout(() => {
    overlay.classList.add('show');
    document.body.style.overflow = 'hidden';
  }, 0);
  window.addEventListener('beforeunload', remove);
  return content;
};
