import { csrfToken, EventOf, on, registerStartup } from 'lib/utils';

registerStartup(() => {
  on('click', '.item-favorite', (ev: EventOf<HTMLElement>) => {
    const fav = ev.currentTarget;
    const previousFavorite = fav.dataset.favorite === 'true';
    fetch(fav.dataset.action!, {
      method: previousFavorite ? 'DELETE' : 'PUT',
      headers: { 'X-CSRF-Token': csrfToken() },
      credentials: 'same-origin',
      mode: 'same-origin',
    })
      .then((res) => res.ok || Promise.reject(new Error(`${res.status}`)))
      .catch((err) => {
        console.error(err);
        toggleFavorite(fav, previousFavorite);
      });
    toggleFavorite(fav, !previousFavorite);
  });
});

function toggleFavorite(fav: HTMLElement, favorite: boolean) {
  fav.classList.toggle('fas', favorite);
  fav.classList.toggle('far', !favorite);
  fav.classList.toggle('text-red-600', favorite);
  fav.dataset.favorite = `${favorite}`;
}
